import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

const Policy = () => {


      useEffect(() => {
      document.getElementById('lang-switch').href = 'https://e-multiconent.com/privacy-policy/'
       });
  return(
  <>
  <Helmet titleTemplate="Privacy policy | Copywriting agency | e-multicontent.com">
    <title>Privacy policy | Copywriting agency | e-multicontent.com</title>
    <meta name="description" content={'✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency.'}/>
    <meta name='image' content={'https://e-multicontent.com/img/meta/privacy-meta.jpg'} />
    <meta name='keywords' content={'copywriting, copywriters, marketing, copywriters agency, Wrocław, team of copywriters, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />
    <meta property="og:description" content={'✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency.'}/>
    <meta property='og:title' content={'Privacy policy | Copywriting agency | e-multicontent.com'} />
    <meta property='og:image' content={'https://e-multicontent.com/img/meta/privacy-meta.jpg'} />
    <meta property="og:url" content={'https://e-multicontent.com/privacy-policy/'}/>
    <link rel="alternate" href="https://e-multicontent.pl/polityka-prywatnosci/" hrefLang="pl-pl" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="e-multicontent.com" />
    <meta property="twitter:url" content="https://e-multicontent.com/privacy-policy/" />
    <meta name="twitter:title" content="Privacy policy | Copywriting agency | e-multicontent.com" />
    <meta name="twitter:description" content="✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency." />
    <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://e-multicontent.com"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Privacy policy",
        " item": "https://e-multicontent.com/privacy-policy/"
      }]
    })}
    </script>
  </Helmet>
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content" style={{margin:'2.5%'}}>
        <div style={{textAlign:'center'}}>
          <img src="/img/privacy.svg" alt="Privacy policy - Copywriting agency - translations and texts, seo for websites, e-commerce and marketplace" style={{width: '200px', }}/>
          <h1 className="subtitle" style={{color:'#36b3d2', textDecoration: 'underline',fontVariant:'all-small-caps'}}><b>Privacy policy and cookies policy - e-multicontent</b></h1>
        </div>

          <p>
          E-multicontent, by running the website at <Link to = "/"> e-multicontent.com </Link>, makes every effort to ensure that your privacy is respected and the information and personal data provided is protected when using the Website and the newsletter, and for this purpose takes all necessary actions.
           By using the Website at <Link to = "/"> e-multicontent.com </Link>, you accept the presented terms of the <b> privacy policy and cookies policy </b>.
          </p>

          <h2 className="subtitle" style={{textAlign:'center'}}>§ 1</h2>

          <p>
          This Privacy and Cookies Policy sets out the rules for the processing and protection of personal data provided by Users and Cookies, as well as other technologies appearing on the e-multicontent.com website.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 2 <br></br>WHO IS THE DATA ADMINISTRATOR?</h2>

          <p>
          The website and personal data administrator is Innovision Group A.Filip Sp. k. NIP 8971898232, KRS: 0000932139
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 3 <br></br>SECURITY OF DATA</h2>

          <p>
          Users' personal data are stored and protected with due diligence, in accordance with the Administrator's internal procedures. The Administrator processes Users' data using appropriate technical and organizational measures that meet the requirements of generally applicable law. These measures are primarily aimed at securing personal data against loss, violation of data integrity and access by unauthorized persons. Only authorized persons who are obliged to keep the data secret or entities to whom data processing has been made available or entrusted on the basis of a legal provision or a contract have access to the data.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 4</h2>

          <p>
          The User's personal data will not be profiled and used for automated decision-making, affecting the rights and obligations or freedoms of the User within the meaning of the GDPR.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 5</h2>

          <p>
          The Administrator reserves the right to make changes to the Privacy Policy. The reason for the changes may be the development of internet technology, changes in generally applicable law or the development of the website, e.g. by the use of new tools by the Administrator. Policy update will be marked on the website.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 6<br></br>PURPOSE OF DATA PROCESSING AND LEGAL BASIS</h2>

          <p>
          The purpose of data processing is to provide the newsletter service, answer the question, handle the query, provide initial information related to establishing cooperation and present the offer, as well as ongoing contact with you in the above-mentioned scope.
          The User's personal data on the Administrator's Website may be processed for the following purposes and legal grounds:
          <ul>
            <li> Determining, investigating or defending against claims - pursuant to art. 6 sec. 1; </li>
            <li> Contact by phone in matters related to answering the question and in order to present an offer and direct marketing - pursuant to art. 6 sec. 1 letter a GDPR (consent) and pursuant to art. 6 sec. 1 letter f of the GDPR (legitimate interest of the administrator); </li>
            <li> Archival and evidential, for the purposes of securing information that may be used to prove facts - pursuant to art. 6 sec. 1 lit. f GDPR (legitimate interest of the administrator); </li>
            <li> The use of cookies on the Website and its subpages - pursuant to art. 6 sec. 1 lit. a GDPR (consent); </li>
            <li> Managing the Administrator's website - pursuant to art. 6 sec. 1 lit. f GDPR (legitimate interest of the administrator); </li>
            <li> Sending the newsletter - pursuant to art. 6 sec. 1 lit. f GDPR (legitimate interest of the administrator consisting in the processing of data for direct marketing) and on the basis of the Act on the provision of electronic services; </li>
            <li> Operating the fanpage under the name emulticontent.ecommerce on Facebook and interacting with users - pursuant to art. 6 sec. 1 lit. f GDPR (legitimate interest of the administrator); </li>
            <li> Operating an account under the name multicontent on LinkedIn and interacting with users - pursuant to art. 6 sec. 1 lit. f GDPR (legitimate interest of the administrator). </li>
          </ul>
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 7</h2>

          <p>
          Providing the data contained in the contact form is voluntary, but necessary and necessary for the implementation of the newsletter service and for answering the question.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 8<br></br>DATA STORAGE PERIODS</h2>
          <p>
          In accordance with applicable law, E-MULTICONTENT may store your personal data only for the period necessary to achieve the purposes for which they are used. Thus, your personal data will be stored for the period necessary to provide the newsletter service and to answer the question.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 9</h2>
          <p>
          The User's data will be transferred to other entities with whom we are entrusted with personal data entrustment agreements and to whom we provide data on the basis of applicable regulations, including subcontractors, entities hosting (storing) personal data for the Administrator, entities providing services in the field of operation and support of ICT systems, entities providing marketing, legal and IT services. In addition, the information you provide may be made available to competent public authorities, if required by applicable law.
          </p>

          <h2 className="subtitle" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 10<br></br>USERS 'RIGHTS</h2>

          <p>
           You have the following data processing rights:
          <ul>
            <li> the right to obtain information about your personal data we store and to access them, </li>
            <li> the right to rectify and supplement your personal data that is incorrect or incomplete, </li>
            <li> the right to request the deletion of your personal data, if there is no valid reason for its continued storage, </li>
            <li> the right to request the restriction of the processing of your personal data if you contest our right to use it, </li>
            <li> the right to object to the processing of your personal data on the basis of the Controller's legitimate interest, </li>
            <li> the right to request the transfer of your personal data to another administrator, if technically possible (on the terms set out in Article 20 of the GDPR), </li>
            <li> the right to submit a complaint to the supervisory body - the President of the Personal Data Protection Office, </li>
            <li> the right to be forgotten if further data processing is not provided for by the currently applicable law. </li>
          </ul>
          The Administrator informs that these rights are not absolute and do not apply to all processing activities of the User's personal data.
          <br></br> <br></br>
          If the User has consented to a specific action, such consent may be withdrawn at any time. Withdrawal of consent does not affect the processing of data which was carried out on the basis of consent before its withdrawal.
          <br></br> <br></br>
          In order to exercise their rights, the User may contact the Administrator via the contact form, indicating the scope of their requests. The answer will be given no later than 30 days from the date of receipt of the request and its justification, unless it is justified to extend this period in accordance with the GDPR.
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 11<br></br>COOKIES</h2>

          <p>
          From the moment the User connects to the website, we start using cookies. These are small text files stored on the end device (computer) of the website User during the time they use it. Their purpose is to remember user preferences and collect statistical information on the use of the website.
          <br></br><br></br>
          Na stronie e-multicontent.com wykorzystujemy następujące rodzaje plików cookie: 
          <ul>
            <li> Cookies necessary for the website to function - responsible for the proper operation of key website processes. Blocking these cookies may impair the proper functioning of the website. </li>
            <li> Functional cookies - they keep the settings selected by the user and allow for personalization of the interface, e.g. remembering the data entered into the form when switching between the pages of the website, the language version of the website, the appearance of the website. </li>
            <li> Cookies used for web analytics - they help us understand how visitors use our website, thanks to which we are able to improve the functioning of our website. With their help, we collect, for example, information about which pages are most frequently visited by our clients, from where they come to our website, which website functionalities are most often selected by them. They are used for activities such as monitoring and keeping statistics on the use of our website, recognizing and eliminating errors on our website, checking the effectiveness of advertisements and working on ensuring that they are properly selected, testing the functionality and layout of our website. </li>
          </ul>
          We analyze the statistics of our website's recipients using the <b> Google Analytics </b> software, it provides anonymous information about the users of our website, which we use to improve it in terms of technology and website content.
          <br></br>
          As reported by Google in <a href="https://privacy.google.com/businesses/adsservices/" rel="noopener noreferrer" target="_blank"> Google Ads Data Protection Terms: Service Information </a>, Google Analytics collects the following types of personal data:
          <ul>
            <li> Online identifiers including cookies. </li>
            <li> IP addresses and device IDs. </li>
            <li> Customer IDs. </li>
          </ul>
            The data generated by the google analytics software is shared with Google.
            <br></br>
            Each individual cookie consists of four basic parts:
            <ul>
            <li> website name: name of the domain or subdomain that set the cookie; </li>
            <li> cookie name: cookie has a name unique to the page that set it; </li>
            <li> expiry date: some cookies expire after closing your browser (so-called session cookie), other cookies will be automatically deleted only after reaching the expiry date that has been set (so-called persistent); </li>
            <li> value: this is the information in the cookie that the website uses to "remember" your previous visit. </li>
            </ul>
            In cookies, we store basic information about Users (e.g. ID) and information needed to optimize and correctly display the content on the website. This information is used to:
          <ul>
            <li> recognizing users logging in to the website, </li>
            <li> logging of Users' visits, which allows to collect data helpful in introducing improvements in the content and navigation method of the website. </li>
            The website does not automatically collect any data, except for the data contained in cookies when using the website.
          </ul>
          When entering the Website for the first time, the User must consent to cookies or take other possible actions indicated in the message in order to be able to continue using the content of the Website. By using the Website, you consent. If you do not want to give such consent - leave the website. You can also always change your browser settings, disable or delete cookies. The necessary information can be found in the "help" tab in the User's browser.
          <br></br> <br></br>
          In any case, you can block the installation of cookies or delete permanent cookies using the appropriate options of your web browser. Blocking cookies may affect the proper functioning of some processes on the website and its adaptation to the user's preferences.
          <br></br> <br></br>
          In addition to cookies, the website may also collect data normally collected by system administrators as part of the so-called logs or log files. The information contained in the logs may include, among others Your IP address, type of platform and web browser, Internet provider and the address of the page from which you entered the Website. These data will not be combined with the personal data provided by you.
          <br></br> <br></br>
          The data is protected by the SSL encryption certificate installed on our website, in addition, all data access points are protected with special passwords in MD5 hash format.</p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 12</h2>

          <p>
          The website may contain links to other websites. They will open in a new browser window or the same window. The administrator is not responsible for the content provided by these websites.
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 13</h2>

          <p>
           In order to use the Administrator's website, it is necessary to have:
           <ul>
           <li> Devices with internet access; </li>
           <li> An active electronic mailbox receiving e-mails; </li>
           <li> A web browser that allows you to view websites; </li>
           <li> Software that allows you to read content in the following formats, e.g. pdf, video, mp3, mp4. </li>
           </ul>
           </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 14</h2>

          <p>
          Using the Website involves sending queries to the server on which the Website is stored. Each query directed to the server is saved in the server logs. Logs include User's IP address, server date and time, information about the web browser and operating system used by the User. Logs are saved and stored on the server. The server logs are used to administer the Website, and their content is not disclosed to anyone except persons and entities authorized to administer the server. The administrator does not use the server logs to identify the User in any way.
          </p>


          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 15<br></br>SOCIAL MEDIA ACCOUNTS</h2>

          <p>
          The administrator of the user's personal data on Facebook fanpage under the name EMULTICONTENT.ECOMMERCE is Innovision Group A.Filip Sp. k.
          </p>

          <p>
          The administrator of the user's personal data in the LINKEDIN account under the name EMULTICONTENT is Innovision Group A.Filip Sp. k.
          </p>

          <p>
          The User's personal data provided on the Fanpage, Account will be processed for the purpose of administering and managing the Fanpage, the Account, communicating with the User, interacting, directing marketing content to the User and creating a Fanpage community.
          </p>

          <p>
          The basis for their processing is the consent of the User and the legitimate interest of the administrator consisting in interacting with Users and Followers of Fanpage. The User voluntarily decides to like / follow Fanpage / Account.
          </p>

          <p>
          The User may stop following the Fanpage / Account at any time. However, the Administrator will not display to the User any content from the Administrator related to Fanpage, Account.
          </p>

          <p>
          The administrator sees the User's personal data as they can. Name, surname or general information that the User places on their profiles as public. The processing of other personal data is carried out by the social network Facebook, LinkedIn and on the terms contained in its regulations.
          </p>

          <p>
          The User's personal data will be processed for the period of running / existence of the Fanpage / Account on the basis of consent expressed by liking / clicking "Follow". Fanpage / Account or interacting. Leaving a comment, sending a message and in order to implement the Administrator's legitimate interests, i.e. marketing of own products or services or defending against claims.
          </p>

          <p>
          The User's personal data may be shared with other recipients of data, such as Facebook, LinkedIn, cooperating advertising agencies or other subcontractors servicing Fanpage, Administrator Account.
          </p>

          <p>
          Other rights of the User are described in this privacy policy. User data may be transferred to third countries in accordance with the Facebook / LinkedIn regulations. However, the data will not be processed in an automated manner within the meaning of the GDPR (having a negative impact on the rights and freedoms of the User).
          </p>

          <h2 className="subtitle" id="cookies" style={{textAlign:'center',fontVariant:'all-small-caps'}}>§ 16</h2>

          <p>
          The content presented on the Website does not constitute specialist (e.g. educational) advice or guidance and does not relate to a specific factual state. If the User wants to get help in a specific matter, he should contact the person authorized to provide such advice or the Administrator using the contact details provided. The Administrator is not responsible for the use of the content contained on the Website or for actions or omissions made on their basis.
          </p>

          <p>
          All content on the Website is subject to the copyright of certain persons and / or the Administrator (e.g. photos, texts, other materials, etc.). The administrator does not agree to copy this content in whole or in part without his express prior consent.
          </p>

          <p>
          The Administrator informs the User that any dissemination of the content provided by the Administrator is a violation of the law and may give rise to civil or criminal liability. The administrator may also claim appropriate compensation for material or non-material losses in accordance with applicable regulations.
          </p>

          <p>
          The administrator is not responsible for illegal use of the materials available on the website.
          </p>

          <p>
          The content posted on the Website is correct as at the date of posting, unless otherwise indicated.
          </p>

          <br></br>
          <br></br>

          <p><b>Date of publication of the Privacy Policy: 07/14/2021</b></p>

          <p><b>Date of the last update: 3.11.2021</b></p>


          <br></br>
          <Link className="btn" to="/"> Go back to the site. </Link>

        </div>
      </div>
    </section>
  </Layout>
  </>
)
}

export default Policy;
